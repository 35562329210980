const oneDay = 24 * 60 * 60
const oneHour = 60 * 60
const oneMinute = 60

export const getDuration = (from: string, to: string, type: number) => {
  if (!from || !to) {
    if (type === 105) {
      return 'Aktivno stanje'
    } else if (type === 106) {
      return 'Pocetno stanje'
    }
    return '0'
  }
  const timeFrom = +new Date(from) / 1000
  const timeTo = +new Date(to) / 1000
  const diff = timeTo - timeFrom

  let time = ''

  if (diff > oneDay) {
    const days = Math.floor(diff / oneDay)
    time += `${days} ${days > 1 ? 'dana' : 'dan'}`
    const hours = Math.floor((diff - days * oneDay) / oneHour)
    time += `, ${hours}h`
    const minutes = Math.floor(
      (diff - days * oneDay - hours * oneHour) / oneMinute
    )
    time += `, ${minutes}m`
    const seconds = Math.floor(
      diff - days * oneDay - hours * oneHour - minutes * oneMinute
    )
    time += `, ${seconds}s`
  } else if (diff > oneHour) {
    const hours = Math.floor(diff / oneHour)
    time += `${hours}h`
    const minutes = Math.floor((diff - hours * oneHour) / oneMinute)
    time += `, ${minutes}m`
    const seconds = Math.floor(diff - hours * oneHour - minutes * oneMinute)
    time += `, ${seconds}s`
  } else if (diff > oneMinute) {
    const minutes = Math.floor(diff / oneMinute)
    time += `${minutes}m`
    const seconds = Math.floor(diff - minutes * oneMinute)
    time += `, ${seconds}s`
  } else if (diff >= 1) {
    const seconds = Math.floor(diff)
    time += `${seconds}s`
  } else {
    time += `0s`
  }
  return time
}
