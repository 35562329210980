import React, { FC, ReactElement, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import CircularProgress from '@mui/material/CircularProgress'
import { formatValue } from '../utils/formatValue'
import { getDuration } from '../utils/getDuration'
import { formatDate, formatTime } from '../utils/getDate'
import Button from '@mui/material/Button'

interface TableContainerProps {
  deviceId: string
}

interface Reading {
  value: number
  type: number
  creation: string
}

const TableContainer2: FC<TableContainerProps> = ({
  deviceId,
}): ReactElement => {
  const [readings, setReading] = useState<Reading[]>([])
  const [loading, setLoading] = useState(true)
  const [doubleType, setDoubleType] = useState(100)

  const handleDoubleValues = (data: Reading[]) => {
    setReading(data.filter((reading) => reading.type === doubleType))
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const response = await fetch(
        `http://sensor.pikado.net/api/readings/${deviceId}`
      )
      const data = await response.json()
      if (deviceId === '10000') {
        handleDoubleValues(data.data.reverse())
      } else {
        setReading(data.data.reverse())
      }
      setLoading(false)
    })()
  }, [deviceId, doubleType])

  return (
    <Box>
      {deviceId === '10000' && (
        <>
          <Button
            onClick={() => setDoubleType(100)}
            variant="outlined"
            sx={{ marginRight: '20px' }}
          >
            °C
          </Button>
          <Button onClick={() => setDoubleType(101)} variant="outlined">
            %
          </Button>
        </>
      )}
      <TableContainer sx={{ width: { xs: '100%', md: 800 } }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <strong>Ocitavanje</strong>
              </TableCell>
              {deviceId !== '10000' && (
                <TableCell align="left">
                  <strong>Trajanje</strong>
                </TableCell>
              )}
              <TableCell align="left">
                <strong>Vrijeme</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Datum</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={deviceId !== '10000' ? 4 : 3}
                  sx={{ py: 3 }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              readings?.map((reading, i) => {
                const { value, type, creation } = reading
                const duration =
                  type === 105
                    ? getDuration(creation, readings[i - 1]?.creation, type)
                    : getDuration(readings[i + 1]?.creation, creation, type)
                return (
                  <TableRow
                    hover
                    key={+new Date(reading.creation) + Math.random() * 100000}
                    tabIndex={-1}
                  >
                    <TableCell align="left">
                      {formatValue(value, type)}
                    </TableCell>
                    {deviceId !== '10000' && (
                      <TableCell align="left">{duration}</TableCell>
                    )}
                    <TableCell align="left">{formatTime(creation)}</TableCell>
                    <TableCell align="left">{formatDate(creation)}</TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TableContainer2
