import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  ListItem: {
    color: '#222',
  },
}))

interface ListItemLinkProps {
  label: string
  to: string
  handleClick?: () => void
}

const NavBarLink: React.FC<ListItemLinkProps> = ({
  label,
  to,
  handleClick,
}) => {
  const classes = useStyles()
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(
        (itemProps, ref) => (
          <NavLink to={to} exact={true} ref={ref} {...itemProps} />
        )
      ),
    [to]
  )
  return (
    <li onClick={handleClick}>
      <ListItem
        button
        component={renderLink}
        activeStyle={{ color: 'white' }}
        className={classes.ListItem}
      >
        <ListItemText primary={label} />
      </ListItem>
    </li>
  )
}

export default NavBarLink
