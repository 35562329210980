import * as React from 'react'
// import './core/appConfig/localization/i18n'
import "./index.css"
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
// import configureStore from './core/state/configureStore'
// import { Provider as ReduxProvider } from 'react-redux'
// const store = configureStore()
const rootEl = document.getElementById('root')

render(
  // <ReduxProvider store={store}>
    <Router>
      <App />
    </Router>,
  // </ReduxProvider>,
  rootEl
)
