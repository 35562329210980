const checkValue = (value: number) => {
  if (value < 10) return `0${value}`
  return value
}

export const formatDate = (value: string) => {
  const parsedDate = new Date(value)
  const date = checkValue(parsedDate.getDate())
  const month = checkValue(parsedDate.getMonth() + 1)
  return `${date}.${month}.${parsedDate.getFullYear()}`
}

export const formatTime = (value: string) => {
  const parsedDate = new Date(value)
  const hours = checkValue(parsedDate.getHours())
  const minutes = checkValue(parsedDate.getMinutes())
  const seconds = checkValue(parsedDate.getSeconds())
  return `${hours}:${minutes}:${seconds}`
}
