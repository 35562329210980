import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

const SelectField = (props: {
  value?: string
  label?: string
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  handleChangeSelection: (arg: string) => void
}): ReactElement => {
  const { t } = useTranslation()
  const { label, handleChangeSelection, ...rest } = props

  const data = ['10000', '10001', '10002']

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select fullWidth {...rest} variant="outlined" label={label}>
        {data.map((id, index) => (
          <MenuItem
            onClick={() => handleChangeSelection(id)}
            key={index}
            value={id}
          >
            {id}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectField
