import React, { FC, ReactElement, useState } from 'react'
import Box from '@mui/material/Box'
import Table from './Table'
import SelectField from '../components/SelectField'

interface HomePageProps {}

const HomePage: FC<HomePageProps> = (): ReactElement => {
  const [id, setId] = useState('10000')
  return (
    <Box p="8px">
      <Box width="200px" mb="25px">
        <SelectField handleChangeSelection={setId} label="Uredjaj" value={id} />
      </Box>
      <Table deviceId={id} />
    </Box>
  )
}

export default HomePage
