import React, { useEffect } from 'react'
// import * as ROUTES from './core/routes/constants'
import { Route, Switch } from 'react-router-dom'
// import SignUp from './registration/RegistrationPage'
// import LogIn from './logIn/LogInPage'
// import ProfilePage from './profile/UserProfilePage'
import HomePage from './home-page/HomePage'
// import PlayerPage from './players/PlayerPage'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  CssBaseline
} from '@mui/material'
import { connect } from 'react-redux'
import Box from '@mui/system/Box'
import NavBar from './components/NavBar'
// import AuthRoute from './core/routes/AuthRoute'
// import { checkSession } from './profile/state/profileActions'
// import { User } from './profile/utils/profileModel'
import PageNotFound from './components/PageNotFound'
import { ROUTES } from './utils/consts'

// interface Props {
//   id: number
//   choosenTheme: string
//   checkSession: () => Promise<void>
// }

const App: React.FC = () => {
  // const handleLoad = async () => {
  //   try {
  //     await checkSession()
  //   } catch (err) {
  //     localStorage.removeItem('token')
  //   }
  // }

  // useEffect(() => {
  //   if (!id && localStorage.getItem('token')) {
  //     handleLoad()
  //   }
  // }, [])

  const overrides = {
    MuiTable: {
      root: {
        '& tr': {
          '& td:nth-last-of-type(2)': {
            textAlign: 'center',
          },
          '& th:nth-last-of-type(2)': {
            textAlign: 'center',
          },
          '& td:nth-last-of-type(1) > div': {
            display: 'flex',
            justifyContent: 'center',
          },
          '& td:nth-last-of-type(1)': {
            textAlign: 'center',
          },
          '& th:nth-last-of-type(1)': {
            textAlign: 'center',
          },
        },
        '& tr:nth-of-type(even)': {
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiButton: {
      root: {
        minWidth: '90px',
        '@media (max-width:600px)': {
          fontSize: '0.68rem',
        },
      },
    },
  }

  const overridesLight = {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #fff inset',
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
  }

  const overridesDark = {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #424242 inset',
          WebkitTextFillColor: '#fff',
        },
      },
    },
  }

  const dark = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1982c4',
      },
      secondary: {
        main: '#ff595e',
      },
      background: {
        default: '#222222',
      },
    },
  })
  const light = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1982c4',
      },
      secondary: {
        main: '#ff595e',
      },
      background: {},
    },
    typography: {
      fontFamily: 'Quicksand, sans-serif',
      fontWeightRegular: 400,
    },
  })

  // let theme = choosenTheme == 'dark' ? dark : light
  let theme = dark
  theme = responsiveFontSizes(light)
  return (
    <ThemeProvider theme={theme}>
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        marginTop="80px"
        paddingBottom="50px"
      >
        <CssBaseline />
        <NavBar />
        <Switch>
          <Route exact path={ROUTES.ROOT} component={HomePage} />
          {/* <AuthRoute exact path={ROUTES.LOGIN} component={LogIn} />
          <AuthRoute exact path={ROUTES.REGISTER} component={SignUp} />
          <AuthRoute exact path={ROUTES.PROFILE} component={ProfilePage} />
          <Route exact path={ROUTES.PLAYER_ID} component={PlayerPage} /> */}
          <Route component={PageNotFound} />
        </Switch>
      </Box>
    </ThemeProvider>
  )
}

// const mapStateToProps = (state: {
//   appConfig: { choosenTheme: string }
//   profile: any
// }) => {
//   return {
//     choosenTheme: state.appConfig.choosenTheme,
//     id: state.profile.id,
//   }
// }

// export default connect(mapStateToProps, null)(App)
export default App
