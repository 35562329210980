export const formatValue = (value: number, type: number) => {
  if (type === 100) {
    return `${value.toFixed(1)}°C`
  } else if (type === 101) {
    return `${value.toFixed(1)}%`
  } else if (type === 105) {
    if (value) {
      return 'Otvoreno'
    } else {
      return 'Zatvoreno'
    }
  } else if (type === 106) {
      if(value === 1) {
        return `${value} Ciklus`
      } else {
        return `${value} Ciklusa`
      }
    
  }
}
